import { render, staticRenderFns } from "./df-page-user-profile-edit.vue?vue&type=template&id=2099610a&"
import script from "./df-page-user-profile-edit.ts?vue&type=script&lang=js&"
export * from "./df-page-user-profile-edit.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports