import { Component, Inject, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import vuescroll from "vuescroll";
import DfStore from "@/vue/domain/store/df-store";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProperty from "@/vue/domain/content/df-property";
import Swal from "sweetalert2";
import DfUser from "@/vue/domain/user/df-user";

@Component({
  components: {
    DfHeaderMobile: () => Utils.externalComponent2("df-header-mobile"),
    DfFormManager: () => Utils.externalComponent2("df-form-manager"),
    DfStoreCard: () => Utils.externalComponent2("df-store-card"),
    vuescroll,
  },
})
export default class DfPageUserProfileEditComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private profileData: any = null;

  get user(): DfUser {
    return this.$store.getters.user;
  }

  set user(user: DfUser) {
    this.$store.dispatch("setUser", user);
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  beforeMount() {
    this.$root.$emit("loadUser", () => {
      this.getProfileForm();
    });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private saveUserProfileData(callbackFunctions: any) {
    const params: any = {
      properties: (this.profileData.properties || []).map((property: DfProperty) => {
        return {
          type: property.type,
          code: property.code,
          values: property.values.map((value: any) => {
            if (property.type === "LIST") {
              const listValue = value.list ? value.list.key : null;
              return listValue ? { list: listValue } : { list: null };
            } else if (property.code === "TAX_CODE" && value.string) {
              value.string = value.string.toUpperCase();
              return value;
            } else {
              return value;
            }
          }),
        };
      }),
    };
    this.service
      .setProfileData(params)
      .then((response: any) => {
        this.user = response;
        Swal.fire({
          title: this.$t("df-page-user-profile.modal.save.title").toString(),
          text: this.$t("df-page-user-profile.modal.save.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-page-user-profile.modal.save.ok").toString(),
        });
        if (callbackFunctions.then) {
          callbackFunctions.then(response);
        }
      })
      .catch((error: any) => {
        if (callbackFunctions.catch) {
          callbackFunctions.catch(error);
        }
      });
  }

  private getProfileForm() {
    this.service
      .getProfileForm()
      .then((profileData: any) => {
        this.applyProfileData(profileData);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private applyProfileData(profileData: any) {
    if (this.user) {
      this.profileData = {
        properties: profileData.properties.map((property: DfProperty) => {
          const userProperty: DfProperty = this.user.properties.find((userProperty: DfProperty) => userProperty.code === property.code);
          if (userProperty && userProperty.values.length) {
            property.values = userProperty.values.map((value: any) => {
              const newValue: any = {};
              if (property.type === "DATE") {
                value = Utils.dateStrigFromTimestamp(value);
              }
              newValue[userProperty.type.toLowerCase()] = value;
              return newValue;
            });
          }
          return property;
        }),
      };
      this.profileData.privacyConsent = true;
      this.profileData.clubCardConsent = true;
    }
  }
}
